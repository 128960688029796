import useJwt from '@/auth/jwt/useJwt'
import Cookies from "js-cookie";
import store from "@/store";
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */

// eslint-disable-next-line arrow-body-style


export const isUserLoggedIn = () => {
  return new Promise((resolve, reject) => {
    try {
      if (!!Cookies.get(useJwt.jwtConfig.storageTokenKeyName) === true) {
          useJwt.checklogin().then(response => {
          const jwtConfigurations = Cookies.get(useJwt.jwtConfig.configurations);
          const showGMB = Cookies.get(useJwt.jwtConfig.userGMBKey);
          const showGMBNav = Cookies.get(showGMB);
          const showGMBDealers = Cookies.get(useJwt.jwtConfig.userDealersKey);
          const showTriggers = Cookies.get(useJwt.jwtConfig.userTriggersKey);
          const showAccess = Cookies.get(useJwt.jwtConfig.userAccessControlKey);
          let menuItems = [
            {
              header: "Dashboard",
            },
          ];
          if (jwtConfigurations === 'true') {
            menuItems.push({
              title: 'Clients',
              icon: 'BriefcaseIcon',
              defaultOpen: true,
              isOpen: true,
              children: [
                {
                  title: 'Overview',
                  route: 'dashboard',
                  // icon: 'GridIcon',
                },
                // {
                //   title: 'Stores',
                //   route: 'stores',
                //   // icon: 'ShoppingBagIcon',
                // },
                // {
                //   title: 'Local Posts',
                //   route: 'local-post',
                //   // icon: 'FileTextIcon',
                // },
              ],
            });
          }
          if (showGMB === 'true') {
            menuItems.push({
              title: "GMB",
              route: "gmb-dashboard",
            });
          }
          if (showGMBDealers ===  'true') {
            menuItems.push({
              title: "GMB For Dealers",
              route: "gmb-dealers-dashboard",
            });
          }
          if (showTriggers === 'true') {
            menuItems.push({
              title: "Triggers",
              route: "triggers",
            });
          }

          if (showAccess === 'true') {
            menuItems.push({
              title: "Access Control",
              route: "access-control",
            });
          }
          // menuItems.push({
          //   title: "D2Scale-AI",
          //   route: "d2scale-ai",
          // });
          //   menuItems.push({
          //   title: "Activity Tracker",
          //   route: "activity-tracker",
          // });
          menuItems = menuItems.concat([
            {
              header: "Recently Visited Clients",
            },
          ]);
           store.commit("verticalMenu/UPDATA_CONFIGURATION_DATA", {
           menuItems
          });
          resolve(true)
        }).catch(error => {
          resolve(false);
        })
      } else {
        resolve(false);
      }
    } catch (error) {
      resolve(false);
    }
  })
  // return !!Cookies.get(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => Cookies.get(useJwt.jwtConfig.userNameKey);

export const getUserToken = () => Cookies.get(useJwt.jwtConfig.storageTokenKeyName);

export const getUserEmail = () => Cookies.get(useJwt.jwtConfig.userEmailKey);

export const getUserImage = () => Cookies.get(useJwt.jwtConfig.adminUrl);
// export const getAllAdminConfigurationsEnabled = () => Cookies.ge
// // Navbar item hiding features.

// export const getAllAdminConfigurationsEnabled = () => Cookies.get(useJwt.jwtConfig.configurations);


